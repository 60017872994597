import React, { useEffect, useState } from "react";
import { fetchInstruction } from "../../../utils/instruction/InstructionApi";
import AddStudentForm from "./AddStudentForm";
import Screening1 from "./ScreeningCases/Screening1";
import {
  screeningPhaseCategory,
  screeningPhaseTwoStudentPendingList,
} from "./Api/ScreeningApi";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import ScreeningQA from "./ScreeningQA";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import Loader from "../../../utils/loader/Loader";
import ServerUpgradtionModal from "../../ServerUpgradtionModal";
import Modal from "../../modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ScreeningDashboard = () => {
  const getTranslateText = useSelector(getTranslatedTextSelector);

  const [studForm, setStudForm] = useState(true);
  const [scrCategoryId, setScrCategoryId] = useState();
  const [data, setData] = useState([]);
  const [serverErrorModal, setServerErrorModal] = useState(false);

  const [tabState, setTabState] = useState();
  const [screeningOneStudentData, setScreeningOneStudentData] = useState([]);
  const [showQna, setShowQna] = useState(false);

  const [profileVisible, setProfileVisible] = useState(false);
  const revierwerId = JSON.parse(localStorage.getItem("userTypeId"));

  const [loader, setLoader] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const [loader2, setLoader2] = useState(true);
  const tab_id = localStorage.getItem("tabsId") ?? 0;
  const parse = require("html-react-parser");
  //const tab_id = 0; //localStorage.getItem("tabsId");

  let language_id = localStorage.getItem("l_id");
  const userId = localStorage.getItem("id");

  const [instruction, setInstruction] = useState();
  const [showInstructionModel, setShowInstructionModel] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const openInstructionModel = () => {
    setShowInstructionModel(true);
  };
  const openStudForm = () => {
    setShowInstructionModel(false);
    setStudForm(false);
  };
  const [state, setState] = useState();

  const getInstruction = (id) => {
    fetchInstruction("second_screening", language_id).then((response) => {
      if (response.response[0] === undefined) {
        language_id = 1;
        console.log(response);
        getInstruction(language_id);
      } else {
        setState(response.response[0]);
        setLoader(false);
      }
    });
  };

  const getScreening1Instruction = (id) => {
    fetchInstruction("screening", language_id).then((response) => {
      if (response.response[0] === undefined) {
        language_id = 1;
        getInstruction(language_id);
      } else {
        setInstruction(response.response[0]);
        //setLoader(false);
      }
    });
  };

  const getScreeningOption = () => {
    screeningPhaseCategory(language_id).then((response) => {
      console.log(response, "response in category");
      if (!response) {
        setServerErrorModal(true);
        return;
      }

      setTabState(response);
      console.log(response, "response manual");
      const item = response.filter((item) => item.id === 1);
      setScrCategoryId(item[0].id);
      setLoader(false);
    });
  };

  console.log("tab-response", tabState);

  useEffect(() => {
    getInstruction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language_id]);

  useEffect(() => {
    getScreening1Instruction();
  }, [showInstructionModel]);

  // Tabs Click Function //
  const handleTabClick = (e) => {
    const convertToNum = Number.parseInt(e.target.id);
    setScrCategoryId(convertToNum);
    localStorage.removeItem("tabsId");
    localStorage.setItem("tabsId", convertToNum);
  };

  // Student Pending List Api //
  const getPendingStudentData = () => {
    setLoader(true);
    setLoader1(true);
    setLoader2(true);
    if (tab_id == "0") {
      setScrCategoryId(0);
    } else if (tab_id === "1") {
      setScrCategoryId(1);
    } else if (tab_id === "2") {
      setScrCategoryId(2);
    } else if (tab_id === "3") {
      setScrCategoryId(3);
    } else {
    }
    //const scrCatId = tab_id > 0 ? tab_id : 1;
    const scrCatId = tab_id;

    screeningPhaseTwoStudentPendingList(
      userId,
      language_id,
      //scrCategoryId
      scrCatId //add to show appropriate screening count and data after navigate from student profile
    ).then((response) => {
      console.log(response, "screening response");
      if (
        (response.data.error === "GMC0002" && response.data.error === "") ||
        language_id > 3
      ) {
        language_id = 1;
        getPendingStudentData();
      } else {
        setScreeningOneStudentData(response.data.response);
        if (scrCatId == "0") {
          setScreeningAllCount(response.data.response.length);
          setScreening1Count(
            response?.data?.response.filter(
              (data) => data.screeningCategoryId === 1
            ).length
          );
          setprofilerFormCount(
            response?.data?.response.filter(
              (data) => data.screeningCategoryId === 2
            ).length
          );
          setAdvanceScreeningCount(
            response?.data?.response.filter(
              (data) => data.screeningCategoryId === 3
            ).length
          );
        } // else if (scrCatId === "1") {
        //   setScreening1Count(response.data.response.length);
        // } else if (scrCatId === "2") {
        //   setprofilerFormCount(response.data.response.length);
        // } else if (scrCatId === "3") {
        //   setAdvanceScreeningCount(response.data.response.length);
        // }
        setLoader(false);
        setLoader1(false);
        setLoader2(false);
      }
    });
  };

  useEffect(() => {
    if (scrCategoryId >= 0) {
      getPendingStudentData();
      // setLoader1(false);
    }
  }, [scrCategoryId]);

  useEffect(
    () => {
      getScreeningOption();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [screeningAllCount, setScreeningAllCount] = useState();
  const [screening1Count, setScreening1Count] = useState();
  const [profilerFormCount, setprofilerFormCount] = useState();
  const [AdvanceScreeningCount, setAdvanceScreeningCount] = useState();

  return (
    <>
      {profileVisible ? (
        <PersonalDetails />
      ) : (
        <>
          {studForm ? (
            <>
              {loader !== true ? (
                <>
                  {!showQna ? (
                    <div className="bg-[#F4F4FA] py-[50px] lg:px-[50px] lg:py-[25px] px-[25px] h-[calc(100%_-_9%)] overflow-y-scroll no-scrollbar">
                      <div>
                        {/* Dashboard Header */}
                        <div className="flex justify-between flex-row items-end">
                          <h3 className="flex text-[#99a0bd] font-semibold text-[18px]">
                            {getTranslateText?.data?.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "dash_label_caseC"
                                )[0].keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "dash_label_caseC"
                                  )[0].keyValue
                                : "My Cases"
                              : "My Cases"}

                            <p className="bg-gradient-to-r from-[#FF9200] to-[#F6BF52] rounded-[23px] text-[14px]  text-white py-[2px] px-[15px] ml-3">
                              {screeningOneStudentData.length}
                            </p>
                          </h3>
                          {screeningOneStudentData?.length === 0 ? (
                            ""
                          ) : (
                            <div className="flex ">
                              <div className="text-[#3C57AD] rounded-[10px] border-[#3C57AD] border-[1px] shadow-[0px_4px_16px_#D3D7E452] py-2 px-4 flex mx-3 text-[14px] hidden">
                                Filter
                                <img
                                  src="../Images/filter-icon.svg"
                                  alt="Filter icon"
                                  className="ml-3"
                                />
                              </div>

                              {/* Here we showing add students button only for All and screening 1 tab */}
                              {(scrCategoryId === 0 || scrCategoryId === 1) && (
                                <button
                                  className={`${
                                    revierwerId === 9 ? "hiddens" : ""
                                  } bg-gradient-to-r from-[#FF9200] to-[#F6BF52] rounded-[10px] text-[12px]  text-white py-[8px] px-[10px] text-[14px]"`}
                                  onClick={openInstructionModel}
                                  //onClick={setShowInstructionModel(true)}
                                >
                                  {getTranslateText?.data?.length > 0
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "key_add_Student"
                                      )[0].keyValue
                                      ? getTranslateText?.data?.filter(
                                          (item) =>
                                            item.pageKey === "key_add_Student"
                                        )[0].keyValue
                                      : "Add Students"
                                    : "Add Students"}
                                  &nbsp;+
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                        {/* Dashboard Header End */}

                        {/* Dashboard Main */}
                        <div className="md:mt-[60px] mt-[20px] lg:mt-[20px] md:bg-[#fff] rounded-[30px] lg:px-[25px]">
                          <div className="py-[10px] text-left md:ml-[20px] ml-0">
                            {tabState?.map((tabs, i) => {
                              return (
                                <button
                                  disabled={tabs.id === 4}
                                  key={i}
                                  id={tabs.id}
                                  onClick={handleTabClick}
                                  className={`${
                                    (localStorage.getItem("tabsId")
                                      ? +localStorage.getItem("tabsId")
                                      : scrCategoryId) === tabs.id
                                      ? "bg-[#2C3EA2] bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff]"
                                      : " bg-[#E3E3EF] text-[#10194280]"
                                  }  px-6 py-[7px] rounded-[25px] md:mx-[10px] my-2 text-[14px] lg:w-[22%] w-[100%] `}
                                >
                                  {tabs.name} &nbsp;{" "}
                                  {tabs.id === 0 && screeningAllCount > 0
                                    ? "(" + screeningAllCount + ")"
                                    : tabs.id === 1 && screening1Count > 0
                                    ? "(" + screening1Count + ")"
                                    : tabs.id === 2 && profilerFormCount > 0
                                    ? "(" + profilerFormCount + ")"
                                    : tabs.id === 3 && AdvanceScreeningCount > 0
                                    ? "(" + AdvanceScreeningCount + ")"
                                    : ""}
                                  {/* {tabs.length} */}
                                </button>
                              );
                            })}
                          </div>
                          <div className="md:bg-[#fff] rounded-[30px] h-auto">
                            {tabState?.map((tabs, i) => (
                              <div key={i}>
                                {scrCategoryId === tabs.id && (
                                  <>
                                    {loader1 !== true ? (
                                      <>
                                        {loader2 !== true ? (
                                          <Screening1
                                            showQna={showQna}
                                            setShowQna={setShowQna}
                                            scrData={screeningOneStudentData}
                                            setData={setData}
                                            tabName={tabs.name}
                                            description={state}
                                            studentForm={openStudForm}
                                            setProfileVisible={
                                              setProfileVisible
                                            }
                                            tabId={tabs.id + 1}
                                            tabNumber={tabs.id}
                                            loader={loader}
                                          />
                                        ) : (
                                          <div className="flex justify-center items-center h-[80%]">
                                            <Loader
                                              type="spinningBubbles"
                                              color="#2e376c"
                                            />
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <div className="flex justify-center items-center h-[80%]">
                                        <Loader
                                          type="spinningBubbles"
                                          color="#2e376c"
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        {/* Dashborad Main End */}
                      </div>
                    </div>
                  ) : (
                    <ScreeningQA
                      setStudForm={setStudForm}
                      studentForm={data}
                      setShowQna={setShowQna}
                    />
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center h-[80%]">
                  <Loader type="spinningBubbles" color="#2e376c" />
                </div>
              )}
            </>
          ) : (
            <AddStudentForm setStudForm={setStudForm} />
          )}
        </>
      )}
      <Modal visible={showInstructionModel}>
        <div>
          {/* Modal Content */}
          <div className=" lg:w-5/12 md:w-8/12 w-10/12 m-auto ">
            <div className="flex justify-between   bg-gradient-to-r from-[#2C3EA2] to-[#192867] px-6 py-4 rounded-t-[30px]">
              <h2 className="text-xl font-semibold  text-[#fff]  text-left">
                {instruction?.screenName}
              </h2>
              <p
                className="bg-[#f6f7fa] rounded-full py-[4px] w-[30px] h-[30px] cursor-pointer"
                onClick={() => setShowInstructionModel(false)}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  size="lg"
                  style={{ color: "#2C3EA2" }}
                />
              </p>
            </div>
            <div className="bg-[#fff] md:px-6 px-3 py-6 rounded-b-[30px]">
              <h5 className="text-center text-2xl font-medium mb-1">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "srneLabel"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "srneLabel"
                      )[0]?.keyValue
                    : "Instructions"
                  : "Instructions"}
              </h5>
              <ol className="list-disc px-6 text-left text-[14px] md:text-[18px]">
                {instruction?.description && parse(instruction?.description)}
              </ol>
              <div className="flex items-center mt-4 ml-5">
                <input
                  type="checkbox"
                  id="acceptInstructions"
                  className="mr-2"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
                <label
                  htmlFor="acceptInstructions"
                  className="text-[14px] md:text-[18px]"
                >
                  I hereby agree with all the points mentioned above.
                </label>
              </div>
              <button
                onClick={openStudForm}
                className={`bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] md:px-[50px] px-[30px] py-[10px] rounded-[10px] mt-[10px] text-center ${
                  !isChecked
                    ? "opacity-50 cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                disabled={!isChecked}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {serverErrorModal && (
        <ServerUpgradtionModal
          show={serverErrorModal}
          setShow={setServerErrorModal}
          apiCall={getScreeningOption}
        />
      )}
    </>
  );
};

export default ScreeningDashboard;
