import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { apiEndPoints } from "../http/EndPoints";
import { baseURLCommApi } from "../http/http-common";
import "../../components/user/notification/notification.css";
import { useNavigate } from "react-router-dom";
import notification from "../../assets/images/notification.png";
import Modal from "../../components/modal/Modal";
import {
  FetchNotifications,
  MarkReadNotification,
  MarkReadAllNotification,
} from "../../components/user/notification/NotificationApi";
import { CaseTransferProcess } from "../../components/user/screening/Api/CaseTransferApi";

const HeaderNotification = () => {
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("id"));
  const [notificationResponse, setNotificationResponse] = useState([]);
  const [showTransferConfirmModal, setShowTransferConfirmModal] =
    useState(false);
  const [fullName, setFullName] = useState("");
  const [schoolMasterName, setSchoolMasterName] = useState("");
  const [address, setAddress] = useState("");
  const [isDown, setIsDown] = useState(false);
  const [caseTransferRequestId, setCaseTransferRequestId] = useState(0);
  const [getNotificationId, setNotificationId] = useState(0);
  const [getGradeId, setGradeId] = useState(0);

  const fetchNotifications = async () => {
    try {
      await FetchNotifications(userId).then((response) => {
        if (response.data.status === true && response.data.statusCode === 200) {
          setNotificationResponse(response.data.response);
        }
        console.log("api-hit");
      });
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const markReadNotification = async (notificationId) => {
    try {
      console.log("markNotification");
      await MarkReadNotification(notificationId).then((response) => {
        if (response.data.status === true && response.data.statusCode === 200) {
          fetchNotifications();
        }
        console.log("api-hit");
      });
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const markReadAllNotification = async () => {
    try {
      if (notificationResponse.filter((data) => data.isRead === 0).length > 0) {
        await MarkReadAllNotification(userId).then((response) => {
          if (
            response.data.status === true &&
            response.data.statusCode === 200
          ) {
            fetchNotifications();
          }
          console.log("api-hit");
        });
        console.log("markreadall api call");
      }
      console.log("markreadall api not call");
    } catch (error) {
      console.error("Error mark read notifications:", error);
    }
  };

  const boxRef = useRef(null);
  // Function to handle click on list items
  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setIsDown(false);
    }
  };

  useEffect(() => {
    if (isDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDown]);

  useEffect(() => {
    fetchNotifications();

    // Set interval to call it every 5 minutes (300,000 ms)
    const intervalId = setInterval(fetchNotifications, 300000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const toggleNotifi = () => {
    setIsDown((prev) => !prev);
  };

  console.log(notificationResponse, "notificationResponse final");

  const caseTransferProcessOnClick = async (
    caseTransferId,
    notificationID,
    gradeID,
    responseId
  ) => {
    try {
      const data = {
        caseTransferRequestId: caseTransferId,
        notificationId: notificationID,
        gradeId: gradeID,
        response: responseId,
      };
      await CaseTransferProcess(data).then((response) => {
        if (response?.data?.message === "Success") {
          window.location.reload(false);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <div className="md:ml-8 mr-8 md:mr-0 icon">
        <a onClick={toggleNotifi}>
          <img alt="" src={notification} />
          <span>
            {notificationResponse.filter((data) => data.isRead === 0).length}
          </span>
        </a>
      </div>
      <div
        id="notificationBox"
        ref={boxRef}
        className={`notification-box ${isDown ? "visible" : "hidden"}`}
      >
        <div className="notification-header">
          <h3>Notifications</h3>
          <button
            className="mark-all-read"
            onClick={() => {
              markReadAllNotification();
            }}
            disabled={
              notificationResponse.filter((data) => data.isRead === 0)
                .length === 0
            }
          >
            Mark All as Read
          </button>
        </div>

        <div className="notification-content">
          {notificationResponse && notificationResponse.length > 0 ? (
            notificationResponse.map((notifi) =>
              notifi.title !== "Case Transfer With Controls" &&
              notifi.title !== "Case Transfer Without Controls" ? (
                <div
                  key={notifi.id}
                  className={`notification-item ${
                    notifi.isRead === 1 ? "read" : "unread"
                  }`}
                >
                  <img
                    src={
                      notifi.icon ||
                      (notifi.isRead === 1
                        ? "../Images/green-tick.png"
                        : "../Images/red-dot.png")
                    }
                    alt="icon"
                    className="notification-icon"
                  />
                  <div className="notification-text">
                    <a
                      onClick={() => {
                        if (notifi.isRead === 0) {
                          markReadNotification(notifi.id);
                        }
                      }}
                    >
                      <h4>{notifi.title}</h4>
                      <p>{notifi.body}</p>
                    </a>
                  </div>
                  <small className="notification-date">
                    {new Date(notifi.sendDate).toLocaleDateString()}
                  </small>
                </div>
              ) : notifi.title === "Case Transfer With Controls" &&
                notifi.body.includes("FromUserDetails") ? (
                <div
                  className="notification-content"
                  style={{ borderBottom: "1px solid #e0e0e0" }}
                >
                  <div className="flex flex-row">
                    <h4 className="card-title ">
                      <b>
                        {JSON.parse(notifi.body).FromUserDetails[0]?.FullName}
                      </b>{" "}
                      from{" "}
                      <b>
                        {
                          JSON.parse(notifi.body).FromUserDetails[0]
                            ?.SchoolMasterName
                        }
                        ,{" "}
                        {JSON.parse(notifi.body).FromUserDetails[0]?.StateName},
                      </b>{" "}
                      has sent a student transfer request
                    </h4>
                    <small className="notification-date">
                      {new Date(notifi.sendDate).toLocaleDateString()}
                    </small>
                  </div>
                  <div className="card-container">
                    <img
                      src={"../Images/userImage-icon.svg"}
                      alt="icon"
                      className="card-image"
                    />

                    <div style={{ flex: 1 }}>
                      <h4 className="card-subtitle">
                        <b>
                          {
                            JSON.parse(notifi.body).StudentDetails[0]
                              ?.StudentName
                          }
                        </b>
                      </h4>
                      <p className="card-grade">
                        {JSON.parse(notifi.body).StudentDetails[0]?.GradeName}
                      </p>

                      <div>
                        <button
                          className="cancel-button"
                          onClick={() => {
                            caseTransferProcessOnClick(
                              JSON.parse(notifi.body).CaseTransferRequestId,
                              notifi.id,
                              JSON.parse(notifi.body).StudentDetails[0]
                                ?.NewGradeID,
                              2
                            );
                            fetchNotifications();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="transfer-button"
                          onClick={() => {
                            setFullName(
                              JSON.parse(notifi.body).FromUserDetails[0]
                                ?.FullName
                            );
                            setSchoolMasterName(
                              JSON.parse(notifi.body).FromUserDetails[0]
                                ?.SchoolMasterName
                            );
                            setAddress(
                              JSON.parse(notifi.body).FromUserDetails[0]
                                ?.StateName
                            );
                            setCaseTransferRequestId(
                              JSON.parse(notifi.body).CaseTransferRequestId
                            );
                            setNotificationId(notifi.id);
                            setGradeId(
                              JSON.parse(notifi.body).StudentDetails[0]
                                ?.NewGradeID
                            );

                            setShowTransferConfirmModal(true);
                            //fetchNotifications();
                            setIsDown(false);
                          }}
                        >
                          Transfer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : notifi.title === "Case Transfer Without Controls" ? (
                <div className="notification-item">
                  <img
                    src={"../Images/userImage-icon.svg"}
                    alt="User Icon"
                    className="w-[50px] h-[50px] rounded-full"
                  />
                  <div className="notification-text">
                    <h4>
                      Transfer request for{" "}
                      <b>Abhishek Naidu, a Class 5 student,</b> has been
                      accepted.{" "}
                    </h4>
                  </div>
                  <small className="notification-date">
                    {new Date(notifi.sendDate).toLocaleDateString()}
                  </small>
                </div>
              ) : (
                " "
              )
            )
          ) : (
            <p className="empty-message">No notifications yet!</p>
          )}
        </div>

        <div className="notification-footer">
          <a
            onClick={() => {
              setIsDown(false);
              navigate("/user/notification");
            }}
            style={{ cursor: "pointer" }}
          >
            View All Notifications
          </a>
        </div>
      </div>

      {/* Transfer request confirm success model */}
      <Modal visible={showTransferConfirmModal}>
        <div className="w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
          <img
            src="../../Images/confirm-icon.svg"
            alt="Puzzle Smiley Icon"
            className="w-[25%] m-auto"
          />
          <br></br>
          <h2 className="text-[18px] my-2 text-[#565D7A]">
            <b> Transfer Confirmation </b>
          </h2>

          <h6 className="text-[14px] text-[#565D7A]">
            You are about to transfer this student to <b> {fullName} </b>
            from{" "}
            <b>
              {" "}
              {schoolMasterName}, {address}.
            </b>
          </h6>
          <br />
          <div
            style={{
              borderRadius: "8px",
              border: "1px solid #FFF5EE",
              backgroundColor: "#FFF4DB",
              margin: "0 5px auto",
            }}
          >
            <h6
              className="text-[14px] text-[#585D74]"
              style={{ margin: "10px 0 10px 0" }}
            >
              Note: Upon transfer, all other transfer requests for this student
              will be automatically canceled.
            </h6>
          </div>
          <div className="flex mt-5">
            {/* Cancel Button */}
            <button
              className="bg-[#fff] text-[#2C3EA2] px-[20px] py-[10px] rounded-[10px] w-[160px] border border-[#2C3EA2] cursor-pointer"
              onClick={() => {
                setShowTransferConfirmModal(false);
              }}
            >
              Cancel
            </button>
            &nbsp; &nbsp; &nbsp;
            {/* Confirm Button */}
            <button
              className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[20px] py-[10px] rounded-[10px] cursor-pointer"
              onClick={() => {
                caseTransferProcessOnClick(
                  caseTransferRequestId,
                  getNotificationId,
                  getGradeId,
                  1
                );
                setShowTransferConfirmModal(false);
              }}
            >
              Confirm Transfer
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HeaderNotification;
