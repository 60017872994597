import React, { useEffect, useState } from "react";
import "./Screening.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import ScreeningQA from "./ScreeningQA";
import { Link, useNavigate } from "react-router-dom";
import { CheckStudentAPi, StartScreeningOne } from "./Api/ScreeningApi";
import {
  genderSelector,
  getGender,
  getGrade,
  gradeSelector,
} from "../../../redux/slices/profile/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  boardSelector,
  educationTypeSelector,
  getBoard,
  getEducationType,
  getOccupation,
  getSchoolType,
  occuptionSelector,
  schoolTypeSelector,
} from "../../../redux/slices/screening/screeningSlices";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import { HiChevronDoubleLeft } from "react-icons/hi";
import Avatars from "../../../utils/avatar/Avatars";

//import stuImage from "../../../assets/images/student-profile-icon.svg";
import axios from "axios";
import { baseURL } from "../../../utils/http/http-common";
// import Select from "react-select/dist/declarations/src/Select";
//import Select from "react-select";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { encrypt } from "../../encryption/Encryption";
import Modal from "../../modal/Modal";
import {
  getSchoolByStudentDetails,
  checkAssessedStudent,
  sendCaseTransferRequest,
} from "./Api/CaseTransferApi";
import Loader from "../../../utils/loader/Loader";

// import stuImage from "./stuImage";

const AddStudentForm = (props) => {
  const [fullName, setFullName] = useState("");
  const [mothersName, setMothersName] = useState("");
  const [fathersName, setFathersName] = useState("");
  const fullNamePattern = /^\S(?:.*\S)?$/;
  const navigate = useNavigate();

  const { setStudForm } = props;
  const userData = JSON.parse(localStorage.getItem("user"));
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const languageId = JSON.parse(localStorage.getItem("l_id"));

  const [detailForm, setDetailForm] = useState(true);
  const [screeningStart, setScreeningStart] = useState(true);
  const [base64String, setBase64String] = useState("");
  const [studentResponse, setStudentResponse] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [proceedDisabled, setProceedDisabled] = useState(true);

  const [studentForm, setStudentForm] = useState({
    //schoolName: userData.schoolName,
    schoolName: "",
    fatherName: "",
    StudentPhotoUrl: "",
    schoolTypeID: 0,
    updatedDate: new Date().toISOString(),
    //schoolId: userData.schoolId,
    schoolId: 0,
    motherProfessionId: 0,
    FatherWhatsAppNo: encrypt(0),
    residence: "",
    FatherMobileNo: encrypt(0),
    gradeId: "",
    updatedBy: userData.id,
    MotherWhatsAppNo: encrypt(0),
    schoolBoardID: 0,
    motherName: "",
    genderId: "",
    ParentsMobileNo: 0,
    priority: 1,
    userId: userData.id,
    educationTypeId: 0,
    createdDate: new Date().toISOString(),
    fatherProfessionId: 0,
    createdBy: userData.id,
    dob: "",
    studentName: "",
    MotherMobileNo: encrypt(0),
    ParentsWhatsAppNo: encrypt(0),
    status: 1,
    studentImage: "",
    activeScreeningCategoryID: 1,
  });
  const dispatch = useDispatch();
  const gendersSelector = useSelector(genderSelector);
  const gradesSelector = useSelector(gradeSelector);
  const occuptionsSelector = useSelector(occuptionSelector);
  const boardsSelector = useSelector(boardSelector);
  const schoolTypesSelector = useSelector(schoolTypeSelector);
  const educationtypesSelector = useSelector(educationTypeSelector);
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const [maxDate, setMaxDate] = useState("");

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const stateId = userInfo.stateId;
  const blockId = userInfo.blockId;
  const districtId = userInfo.districtId;
  const userId = userInfo.id;
  const [schoolsList, setSchoolsList] = useState([]);
  const [schoolEnter, setSchoolEnter] = useState(false);

  useEffect(() => {
    const today = new Date();
    const maxDate = new Date(
      today.getFullYear() - 3,
      today.getMonth(),
      today.getDate()
    )
      .toISOString()
      .split("T")[0];
    setMaxDate(maxDate);
  }, []);
  // Include relevant dependencies here
  const getValue = () => {
    dispatch(getGender());
    dispatch(getGrade(languageId));
    dispatch(getOccupation(languageId));
    dispatch(getBoard(languageId));
    dispatch(getSchoolType(languageId));
    dispatch(getEducationType(languageId));
  };

  const checkStudentDetail = () => {
    const checkSchoolExists = schoolsList.filter((item) => {
      return item.label === studentForm.schoolName;
    });
    if (schoolEnter === true) {
      if (checkSchoolExists.length >= 1) {
        toast.warn("School Already Exists");
      } else {
      }
    }
    setDetailForm(false);

    CheckStudentAPi(studentForm).then((response) => {
      if (response.status === 1 && response.message === "New Student") {
        setDetailForm(false);
      }
    });
  };

  // const startScreening = () => {
  //   setScreeningStart(false);
  // };

  useEffect(() => {
    getValue();
    console.log(studentForm);
    setProceedDisabled(
      studentForm.studentName === "" ||
        studentForm.dob === "" ||
        studentForm.genderId === "" ||
        studentForm.gradeId === "" ||
        studentForm.fatherName === "" ||
        studentForm.schoolId == 0
    );
    console.log(proceedDisabled);
  }, [studentForm]);

  const handleProfilPic = (e) => {
    const imageFile = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = function () {
      setBase64String(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    setStudentForm({ ...studentForm, studentImage: imageFile });
  };

  const handlePhoneNumberValidation = (phoneNumber) => {
    const phoneNumberRegex =
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    if (phoneNumber?.match(phoneNumberRegex)) {
      return setPhoneNumberError(false);
    } else if (phoneNumber === "") {
      return setPhoneNumberError(false);
    } else {
      return setPhoneNumberError(true);
    }
  };

  const startScreeningDisabled =
    studentForm.fatherName === "" ||
    studentForm.motherName === "" ||
    studentForm.schoolBoardID === 0 ||
    studentForm.schoolTypeID === 0 ||
    // studentForm.studentPhotoUrl === "" ||
    studentForm.parentsMobileNo === "" ||
    studentForm.residence === "" ||
    studentForm.educationTypeId === 0 ||
    // studentForm.studentImage === "" ||
    phoneNumberError === true;

  const startScreeningOne = (id) => {
    //commented on 2024-11-12 bcs special educator and professoinal usertype process same as teacher
    // if (userDetails.userTypeId === 7 || userDetails.userTypeId === 8) {

    //   const formValues = {
    //     ...studentForm,
    //     ParentsMobileNo: encrypt(studentForm.ParentsMobileNo),
    //     schoolName: studentForm.schoolName[0].label,
    //     schoolId: id || studentForm.schoolName[0].value,
    //   };

    //   StartScreeningOne(formValues, base64String).then((response) => {
    //     if (response.status === 1 && response.message === "Success") {
    //       const studentDetail = response?.response[0];
    //       localStorage.setItem("student_detail", JSON.stringify(studentDetail));
    //       setScreeningStart(false);
    //     } else if (response.errorCode === "PSD00002") {
    //       alert(response.message);
    //     }
    //   });
    // } else {
    const formValues = {
      ...studentForm,
      dob: encrypt(studentForm.dob),
      parentsMobileNo: encrypt(studentForm.parentsMobileNo),
    };

    StartScreeningOne(formValues, base64String).then((response) => {
      if (response.status === 1 && response.message === "Success") {
        const studentDetail = response?.response[0];
        localStorage.setItem("student_detail", JSON.stringify(studentDetail));
        setScreeningStart(false);
      } else if (response.errorCode === "PSD00002") {
        alert(response.message);
      }
    });
    //}
  };
  //
  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    if (fullNamePattern.test(inputValue)) {
      // If the input is valid, update the state
      setFullName(inputValue);
    }
    // Optionally, you can provide feedback to the user if the input is invalid.
  };
  //
  const handleKeyboardEvent = (e) => {
    if (fullName.trim() === "" && e.key === " ") {
      e.preventDefault();
    }
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const handleFathersInputChange = (e) => {
    // Allow only alphabets and spaces
    const newInput = e.target.value.replace(/[^a-zA-Z\s]/g, "");
    setStudentForm({
      ...studentForm,
      fatherName: newInput,
    });
  };

  const handleMothersInputChange = (e) => {
    // Allow only alphabets and spaces
    const newInput2 = e.target.value.replace(/[^a-zA-Z\s]/g, "");
    // setFatherName(newInput);
    setStudentForm({
      ...studentForm,
      motherName: newInput2,
    });
  };

  // School list api calling //
  const schoolList = async (stateId, districtId, blockId) => {
    try {
      const response = await axios.get(
        `${baseURL}/School/GetSchools/${stateId}/${districtId}/${blockId}`
      );
      const schoolData = response.data.response;

      const customisedOption = [{ value: 0, label: "other" }].concat(
        schoolData?.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setSchoolsList(customisedOption);
      return response;
    } catch (error) {
      console.log("Error fetching data", error);
      throw error;
    }
  };

  // Add School Api Calling //
  const addSchool = async (stateId, districtId, blockId, userId) => {
    // const body = { Name: studentForm.schoolName };
    let schoolid;
    let formData = new FormData();

    formData.append("Name", studentForm?.schoolName);
    try {
      const response = await axios.post(
        `${baseURL}/School/AddSchool?StateId=${stateId}&DistrictId=${districtId}&BlockId=${blockId}&UserId=${userId}`,
        formData
      );
      startScreeningOne(response.data.response[0].id);
      return response;
    } catch (error) {
      console.log("Error Fetching Data", error);
      throw error;
    }
  };

  useEffect(() => {
    schoolList(stateId, districtId, blockId);
  }, []);

  const [getSchoolData, setGetSchoolData] = useState([]);

  const getSchool = async () => {
    setLoaderShow(true);
    await getSchoolByStudentDetails(
      userId,
      studentForm.studentName,
      fathersName,
      encrypt(studentForm.dob)
    ).then((response) => {
      setGetSchoolData(response);
    });
    setTimeout(() => {
      setLoaderShow(false);
    }, 1000);
  };

  const [showAlreadyExistsModal, setShowAlreadyExistsModal] = useState(false);
  const [showAlreadyRequestModal, setShowAlreadyRequestModal] = useState(false);
  const [showAlreadyAssessedModal, setShowAlreadyAssessedModal] =
    useState(false);

  const [showRequestSentModal, setShowRequestSentModal] = useState(false);
  const [showTransferSuccessModal, setShowTransferSuccessModal] =
    useState(false);
  const [assessedTeacherName, setAssessedTeacherName] = useState("");
  const [assessedTeacherId, setAssessedTeacherId] = useState(0);
  const [assessedTeacherSchoolName, setAssessedTeacherSchoolName] =
    useState("");
  const [assessedStudentId, setAssessedStudentId] = useState(0);
  const [assessedStudentName, setAssessedStudentName] = useState("");
  const [isLoaderShow, setLoaderShow] = useState(false);

  const checkAssessdStudentOnClick = async () => {
    try {
      await checkAssessedStudent(
        userId,
        studentForm.studentName,
        studentForm.fatherName,
        studentForm.schoolId,
        encrypt(studentForm.dob)
      ).then((response) => {
        if (
          response?.data?.status === 1 &&
          response?.data?.message === "Error! Student already exist" &&
          response?.data?.response.length > 0
        ) {
          setShowAlreadyExistsModal(true);
        } else if (
          response?.data?.status === 1 &&
          response?.data?.message === "Already Assessed" &&
          response?.data?.response.length > 0
        ) {
          setAssessedTeacherName(response?.data?.response[0]?.toUserFullName);
          setAssessedTeacherSchoolName(response?.data?.response[0]?.schoolName);
          setAssessedTeacherId(response?.data?.response[0]?.toUserId);
          setAssessedStudentId(response?.data?.response[0]?.studentId);
          setAssessedStudentName(response?.data?.response[0]?.studentName);
          setShowAlreadyAssessedModal(true);
        } else if (
          response?.data?.status === 1 &&
          response?.data?.message === "Already Requested" &&
          response?.data?.response.length > 0
        ) {
          setShowAlreadyRequestModal(true);
        } else if (
          response?.data?.status === 1 &&
          response?.data?.message === "New Student"
        ) {
          setDetailForm(false);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const sendCaseTransferRequestOnClick = async () => {
    try {
      const data = {
        fromUserId: userId,
        toUserId: assessedTeacherId,
        studentID: assessedStudentId,
        gradeID: studentForm.gradeId,
        response: 0,
      };
      await sendCaseTransferRequest(data).then((response) => {
        if (response?.data?.message === "Success") {
          setShowAlreadyAssessedModal(false);
          setShowRequestSentModal(true);
        } else if (response?.data?.message === "Transfer Success") {
          setShowAlreadyAssessedModal(false);
          setShowTransferSuccessModal(true);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  console.log(studentForm, "student form form");

  return (
    <>
      {screeningStart ? (
        <div className="bg-[#F4F4FA] py-[30px] lg:px-[50px] px-[20px] md:h-[calc(100%_-_9%)] h-[calc(100%_-_6%)] overflow-y-scroll no-scrollbar ">
          <div className="bg-[#fff] rounded-[25px] h-[100%] overflow-y-scroll no-scrollbar">
            {detailForm ? (
              <>
                {isLoaderShow === false ? (
                  <>
                    <div className="clsnames rounded-[20px] bg-gradient-to-b from-[#1941d43d] from-[0%] via-[#fff] via-[30%] to-[#fff] to-[70%] flex items-center py-[20px]">
                      <div className="flex ml-5">
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          style={{ color: "#000000" }}
                          size="xl"
                          onClick={() => navigate(-1)}
                          className="mr-5 cursor-pointer"
                        />
                      </div>
                      <h2 className="text-[#3C3F4F] font-semibold text-left text-[24px] mt-[] ">
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "label_student_dt"
                            )[0].keyValue
                          : "Student Details"}
                      </h2>
                    </div>
                    <div className="flex flex-wrap flex-row lg:mx-[50px] mx-[20px] lg:mt-[30px] text-left [&_label]:lg:w-1/2 [&_label]:w-full [&_label]:lg:px-[80px] [&_label]:px-[40px] [&_label]:flex-col [&_label]:flex [&_label]:my-[20px] [&_input]:border-[1px] [&_input]:rounded-[10px] [&_input]:border-[#e8eaf1] [&_input]:py-[10px] [&_input]:px-[15px] [&_select]:border-[1px] [&_select]:rounded-[10px] [&_select]:border-[#e8eaf1] [&_select]:py-[10px] [&_select]:px-[15px] [&_p]:text-[#000] [&_p]:font-semibold [&_p]:mb-3 [&_span]:text-[#9B0000]">
                      <label>
                        <p>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "field_student_name"
                              )[0].keyValue
                            : "Student Name"}
                          <span>*</span>
                        </p>
                        <input
                          type="text"
                          placeholder={
                            getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "hint_student_name"
                                )[0].keyValue
                              : "Enter student name here"
                          }
                          name="studentName"
                          value={fullName}
                          maxLength={30}
                          minLength={2}
                          onChange={(e) => {
                            setFullName(e.target.value);

                            setStudentForm({
                              ...studentForm,
                              studentName: e.target.value,
                            });
                          }}
                          onKeyDown={handleKeyboardEvent}
                        />
                      </label>

                      {/* <label>
                    <p>
                      Last Name<span>*</span>
                      
                    </p>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      onChange={(e) => {
                        setStudentForm({
                          ...studentForm,
                          lastName: e.target.value,
                        });
                      }}
                    />
                  </label> */}
                      <label>
                        <p>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "field_dob"
                              )[0].keyValue
                            : "Date of birth"}
                          <span>*</span>
                        </p>
                        <input
                          type="date"
                          placeholder="DD-MM-YYYY"
                          // max={`${new Date().toISOString().split("T")[0]}`}
                          // max={maxDate}
                          max={currentDate}
                          name="dob"
                          // onChange={(e) => {
                          //   setStudentForm({
                          //     ...studentForm,
                          //     dob: e.target.value,
                          //   });
                          // }}
                          value={studentForm?.dob}
                          onChange={(e) => {
                            const enteredDate = new Date(e.target.value);

                            const currentDate = new Date();
                            const minDate = new Date(
                              currentDate.getFullYear() - 3,
                              currentDate.getMonth(),
                              currentDate.getDate()
                            );

                            if (enteredDate > minDate) {
                              // DOB is less than 18 years
                              //  setShowDobErrorMessage(true);
                            } else {
                              // DOB is greater than or equal to 18 years
                              //  setShowDobErrorMessage(false);
                              setStudentForm({
                                ...studentForm,
                                dob: e.target.value,
                              });
                            }
                            // handleChange(e);
                          }}
                          required
                        />
                      </label>

                      <label>
                        <p>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "key_father_Name"
                              )[0].keyValue
                            : "Father's name"}
                          <span>*</span>
                        </p>
                        <input
                          type="text"
                          placeholder={
                            getTranslateText?.data?.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "hint_fathers"
                                )[0].keyValue
                              : "Enter Father's name"
                          }
                          maxLength={20}
                          minLength={2}
                          required
                          value={fathersName}
                          onChange={(e) => {
                            setFathersName(e.target.value);
                            setStudentForm({
                              ...studentForm,
                              fatherName: e.target.value,
                            });
                            handleFathersInputChange(e);
                          }}
                          onKeyDown={handleKeyboardEvent}
                          onBlur={() => {
                            if (
                              studentForm.studentName !== "" &&
                              studentForm.dob !== "" &&
                              fathersName !== ""
                            ) {
                              getSchool();
                            }
                          }}
                        />
                      </label>

                      {/* {userDetails.userTypeId !== 8 ? (
                  userDetails.userTypeId === 7 ? (
                    <>
                      <label>
                        <p>
                          {getTranslateText?.data?.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "field_school"
                              )[0].keyValue
                            : "School"}
                          <span>*</span>
                        </p>
                        <Select
                          // className={`${
                          //   isProfileCompleted() === true
                          //     ? "[&_.css-13cymwt-control]:bg-[#eee] pointer-events-none text-[#000000a8]"
                          //     : ""
                          // } bg-[#F2F8FB]`}
                          placeholder={
                            getTranslateText?.data?.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "error_School"
                                )[0].keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "error_School"
                                  )[0].keyValue
                                : "Please select your School"
                              : "Please select your School"
                          }
                          options={schoolsList}
                          value={studentForm.schoolName}
                          required
                          onChange={(selectedOption) => {
                            if (selectedOption.value === 0) {
                              setSchoolEnter(true);
                              setStudentForm({
                                ...studentForm,
                                // schoolId: [selectedOption],
                                schoolName: [],
                              });
                            } else {
                              setStudentForm({
                                ...studentForm,
                                // schoolId: [selectedOption],
                                schoolName: [selectedOption],
                              });
                              setSchoolEnter(false);
                            }
                            //   // setState({
                            //   //   ...state,
                            //   //   schoolId: [{ value: 0, label: "other" }],
                            //   // });
                            //   setState({
                            //     ...state,
                            //     schoolId: [selectedOption],
                            //   });
                            //   setShowSchoolName(true);
                            // } else {
                            //   setShowSchoolName(false);
                            //   setState({
                            //     ...state,
                            //     schoolId: [selectedOption],
                            //   });
                            // }
                          }}
                        />
                        {schoolEnter ? (
                          <input
                            type={"text"}
                            // name="schoolName"
                            className="bg-[#F2F8FB] mr-2 mt-2"
                            required
                            placeholder={
                              getTranslateText?.data?.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "hint_school"
                                  )[0].keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "hint_school"
                                    )[0].keyValue
                                  : "Enter school name here"
                                : "Enter school name here"
                            }
                            maxLength={150}
                            // value={studentForm.schoolName}
                            onChange={(e) => {
                              setStudentForm({
                                ...studentForm,
                                schoolName: e.target.value,
                              });
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </label>
                    </>
                  ) : ( */}
                      <>
                        {" "}
                        <label>
                          <div className="flex flex-row justify-between">
                            <p>
                              {getTranslateText?.data.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "field_school"
                                  )[0].keyValue
                                : "School"}
                              <span>*</span>
                            </p>
                            {/* Change school name code comment because school change process follow from user profile page*/}
                            {/* <Link to={"/user/profile"}>
                          <p
                            className={`${
                              userDetails?.userTypeId === 9 ? "hidden" : ""
                            } !text-[#2C519A] underline cursor-pointer"`}
                          >
                            {getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "label_change"
                                )[0].keyValue
                              : "Change"}
                          </p>
                        </Link> */}
                          </div>

                          {/* <input
                        type="text"
                        placeholder="Enter School Name"
                        name="schoolName"
                        value={studentForm.schoolName}
                      /> */}
                          <select
                            name="schoolId"
                            onChange={(e) => {
                              console.log(e.target.value);
                              setStudentForm({
                                ...studentForm,
                                schoolId: e.target.value,
                                schoolName:
                                  e.target.options[e.target.selectedIndex].text, // Update the schoolName correctly
                              });
                            }}
                          >
                            <option value={0} disabled selected>
                              Please select school
                            </option>
                            {getSchoolData?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </>
                      {/* )) : ("")} */}

                      <label>
                        <p>
                          {getTranslateText?.data?.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item?.pageKey === "field_class"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item?.pageKey === "field_class"
                                )[0]?.keyValue
                              : "Class"
                            : "Class"}
                          <span>*</span>
                        </p>
                        <select
                          name="gradeId"
                          value={studentForm?.gradeId}
                          onChange={(e) => {
                            const selectedGradeId = e.target.value;
                            setStudentForm({
                              ...studentForm,
                              gradeId: selectedGradeId,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            {getTranslateText?.data?.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) =>
                                    item?.pageKey === "text_select_class"
                                )[0]?.keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item?.pageKey === "text_select_class"
                                  )[0]?.keyValue
                                : "Please Select a Class"
                              : "Please Select a Class"}
                          </option>
                          {gradesSelector &&
                            gradesSelector?.data?.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                        </select>
                      </label>

                      <label>
                        <p>
                          {getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "field_gender"
                              )[0].keyValue
                            : "Gender"}
                          <span>*</span>
                        </p>
                        <select
                          name="genderId"
                          value={studentForm.genderId}
                          onChange={(e) => {
                            const selectedGenderId = e.target.value;
                            setStudentForm({
                              ...studentForm,
                              genderId: selectedGenderId,
                            });
                          }}
                        >
                          <option value="" disabled>
                            {getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "field_gender"
                                )[0].keyValue
                              : "Gender"}
                          </option>
                          {gendersSelector?.data?.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label === "Male" ? (
                                <>
                                  {getTranslateText?.data.length > 0
                                    ? getTranslateText?.data?.filter(
                                        (item) => item.pageKey === "gender_male"
                                      )[0].keyValue
                                    : "Male"}
                                </>
                              ) : item.label === "Female" ? (
                                <>
                                  {getTranslateText?.data.length > 0
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "gender_female"
                                      )[0].keyValue
                                    : "Female"}
                                </>
                              ) : (
                                <>
                                  {getTranslateText?.data.length > 0
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "gender_other"
                                      )[0].keyValue
                                    : "Other"}
                                </>
                              )}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                    <button
                      className={`bg-gradient-to-r from-[#2235A1] to-[#121F55] rounded-[10px] py-[10px] px-[80px] text-white m-auto text-center mt-[30px] mb-[30px] ${
                        proceedDisabled
                          ? "bg-gradient-to-r from-[#3a3e58] to-[#777f94]"
                          : ""
                      }`}
                      disabled={proceedDisabled}
                      onClick={() => {
                        //console.log("clicked", proceedDisabled);
                        //checkStudentDetail();    commented to check case transfer process
                        // nextForm();
                        checkAssessdStudentOnClick();
                      }}
                    >
                      {" "}
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "btn_proceed"
                          )[0].keyValue
                        : "Proceed"}
                    </button>
                  </>
                ) : (
                  <>
                    <div
                      className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-opacity-0.5"
                      style={{ backgroundColor: "#D3D3D3" }}
                    >
                      <Loader type="spinningBubbles" color="#2e376c" />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="clsnames rounded-[20px] bg-gradient-to-b from-[#1941d43d] from-[0%] via-[#fff] via-[30%] to-[#fff] to-[70%] flex flex-row justify-between items-center px-[50px] pt-[10px]">
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={{ color: "#000000" }}
                      size="xl"
                      //onClick={() => navigate(-1)}
                      onClick={() => setDetailForm(true)}
                      className="mr-5 cursor-pointer"
                    />
                    <h2 className="text-[#3C3F4F] font-semibold text-left md:text-[24px] text-[20px] py-[20px]  mt-[] ">
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "more_about_student"
                          )[0].keyValue
                        : "More About Your Student "}
                    </h2>
                  </div>

                  <p className="bg-[#f6f7fa] rounded-full py-[8px] w-[40px] h-[40px] cursor-pointer hidden">
                    <FontAwesomeIcon
                      icon={faXmark}
                      size="lg"
                      style={{ color: "#3c3f4f" }}
                    />
                  </p>
                </div>
                <div className=" flex flex-row justify-between items-center lg:mx-[120px] mx-[20px] mt-[20px]">
                  <div className="flex lg:flex-row flex-col items-center">
                    <img
                      src={
                        base64String && base64String
                          ? base64String
                          : "../Images/student-profile-icon.svg"
                      }
                      className="border-[#CACEE2] border-[1px] p-2 rounded-full w-[75px] h-[75px]"
                    />
                    <p className="text-[#000000] md:text-[22px] text-[18px] font-semibold ml-5">
                      {studentForm.studentName}
                    </p>
                  </div>
                  <label onChange={handleProfilPic} className="relative">
                    <input type="file" className="absolute w-0 h-0"></input>
                    <p className="text-[#2C519A] underline font-semibold cursor-pointer">
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "label_upload_photo"
                          )[0].keyValue
                        : "Upload Photo"}
                      {/* <span className="text-[#AF3333] ">*</span> */}
                    </p>
                  </label>

                  {/* <p className="text-[#2C519A] underline text-[18px] font-medium cursor-pointer">
                    Upload Photo
                    <span className="text-[#AF3333] !no-underline ">*</span>
                  </p> */}
                </div>

                <div className="flex flex-wrap flex-row lg:mx-[50px] mx-[20px] mt-[30px] text-left [&_label]:lg:w-1/2 [&_label]:w-full [&_label]:px-[20px] [&_label]:lg:px-[80px] [&_label]:flex-col [&_label]:flex [&_label]:my-[20px] [&_input]:border-[1px] [&_input]:rounded-[10px] [&_input]:border-[#e8eaf1] [&_input]:py-[10px] [&_input]:px-[15px] [&_select]:border-[1px] [&_select]:rounded-[10px] [&_select]:border-[#e8eaf1] [&_select]:py-[10px] [&_select]:px-[15px] [&_p]:text-[#000] [&_p]:font-semibold [&_p]:mb-3 [&_span]:text-[#9B0000]">
                  <p className="!text-[#8F95B4] w-full text-[18px] !font-normal mb-3 lg:px-[80px] px-[20px] mt-[20px]">
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "key_parent_info"
                        )[0].keyValue
                      : "Parents Information"}
                  </p>

                  <label>
                    <p>
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "key_father_Prof"
                          )[0].keyValue
                        : "Father's profession"}
                    </p>
                    <select
                      value={studentForm.fatherProfessionId}
                      onChange={(e) => {
                        const selectedProfessionId = e.target.value;
                        setStudentForm({
                          ...studentForm,
                          fatherProfessionId: selectedProfessionId,
                        });
                      }}
                    >
                      <option value="">
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "hint_slt_fatherPro"
                            )[0].keyValue
                          : "Select Father's Profession"}
                      </option>
                      {occuptionsSelector &&
                        occuptionsSelector?.data?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </label>
                  <label>
                    <p>
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "key_mother_name"
                          )[0].keyValue
                        : "Mother's name"}
                      <span>*</span>
                    </p>
                    <input
                      type="text"
                      placeholder={
                        getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "key_mother_name"
                            )[0].keyValue
                          : "Mother's name"
                      }
                      maxLength={20}
                      minLength={2}
                      required
                      value={studentForm.motherName}
                      onChange={(e) => {
                        setMothersName(e.target.value);

                        handleMothersInputChange(e);
                      }}
                      onKeyDown={handleKeyboardEvent}
                    />
                  </label>
                  <label>
                    <p>
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "key_mother_prof"
                          )[0].keyValue
                        : "Mother's profession"}
                    </p>
                    <select
                      value={studentForm.motherProfessionId}
                      onChange={(e) => {
                        const selectedProfessionId = e.target.value;
                        setStudentForm({
                          ...studentForm,
                          motherProfessionId: selectedProfessionId,
                        });
                      }}
                    >
                      <option value="">
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "hint_slt_motherPro"
                            )[0].keyValue
                          : "Select Mother Profession"}
                      </option>
                      {occuptionsSelector &&
                        occuptionsSelector?.data?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </label>
                  <label>
                    <div className="flex flex-row justify-between">
                      <p>
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "parent_mob_no"
                            )[0].keyValue
                          : "Parents Mobile Number"}{" "}
                        <span>*</span>
                      </p>
                    </div>
                    <div className="relative">
                      <img
                        src="../Images/indian-flag.png"
                        className="absolute left-[10px] top-[12.5px] border-r-[1px] border-[#828CB0] pr-[10px]"
                      />
                      <input
                        type="text"
                        placeholder={
                          getTranslateText?.data.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "parent_mob_no"
                              )[0].keyValue
                            : "Enter Mobile number"
                        }
                        className="w-full !pl-[50px]"
                        value={studentForm?.parentsMobileNo}
                        maxLength={10}
                        minLength={10}
                        onChange={(e) => {
                          setStudentForm({
                            ...studentForm,
                            parentsMobileNo: e.target.value,
                          });
                        }}
                        onKeyUp={() => {
                          handlePhoneNumberValidation(
                            studentForm.parentsMobileNo
                          );
                        }}
                      />
                    </div>
                    <span>
                      {phoneNumberError ? "Invalid Phone Number" : <></>}
                    </span>
                  </label>
                  <div className="mb-3 lg:px-[80px] px-[20px] mt-[20px] relative">
                    <p>
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "hint_residence"
                          )[0].keyValue
                        : "select residence"}
                      <span>*</span>
                    </p>
                    <div className="flex flex-row justify-around items-center [&_p]:py-[10px]">
                      <p>
                        <input
                          type="radio"
                          name="residence"
                          onClick={() => {
                            setStudentForm({
                              ...studentForm,
                              residence: "urban",
                            });
                          }}
                        />{" "}
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "label_urban"
                            )[0].keyValue
                          : "Urban"}
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="residence"
                          onClick={() => {
                            setStudentForm({
                              ...studentForm,
                              residence: "rural",
                            });
                          }}
                        />{" "}
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "label_rural"
                            )[0].keyValue
                          : "Rural"}
                      </p>
                    </div>
                  </div>
                  <p className="!text-[#8F95B4] w-full text-[18px] !font-normal mb-3 lg:px-[80px] px-[20px] mt-[20px]">
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "label_education_det"
                        )[0].keyValue
                      : "Educational Information"}
                  </p>

                  <label className="!w-full">
                    <p>
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "select_education_status"
                          )[0].keyValue
                        : "Education Type"}
                      <span>*</span>
                    </p>
                    <select
                      value={studentForm.selectedEducationTypeId}
                      onChange={(e) => {
                        const selectedEducationTypeId = e.target.value;
                        setStudentForm({
                          ...studentForm,
                          educationTypeId: selectedEducationTypeId,
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "hint_education_type"
                            )[0].keyValue
                          : "Please Select Education details"}
                      </option>
                      {educationtypesSelector &&
                        educationtypesSelector?.data?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </label>

                  {/* <label className="!w-full">
                    <p>
                      Education status<span>*</span>
                    </p>
                    <input
                      type="text"
                      value="Aman Sharma"
                      disabled
                      className="uppercase bg-[#f6f7fa] text-[#000] "
                    />
                  </label> */}
                  <label>
                    <p>
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "select_school_board"
                          )[0].keyValue
                        : "Board"}
                      <span>*</span>
                    </p>
                    <select
                      value={studentForm.selectedBoardId}
                      onChange={(e) => {
                        const selectedBoardId = e.target.value;
                        setStudentForm({
                          ...studentForm,
                          schoolBoardID: selectedBoardId,
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "select_school_board"
                            )[0].keyValue
                          : "Board"}
                      </option>
                      {boardsSelector &&
                        boardsSelector?.data?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </label>

                  <label>
                    <p>
                      {" "}
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "key_school_type"
                          )[0].keyValue
                        : " Type of school"}
                      <span>*</span>
                    </p>
                    <select
                      value={studentForm.selectedSchoolTypeId}
                      onChange={(e) => {
                        const selectedSchoolTypeId = e.target.value;
                        setStudentForm({
                          ...studentForm,
                          schoolTypeID: selectedSchoolTypeId,
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "select_school_type"
                            )[0].keyValue
                          : "Select Type of School"}{" "}
                      </option>
                      {schoolTypesSelector &&
                        schoolTypesSelector?.data?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </label>
                  <button
                    className={`bg-gradient-to-r from-[#2235A1] to-[#121F55] rounded-[10px] py-[10px] px-[80px] text-white m-auto text-center my-[30px] ${
                      startScreeningDisabled
                        ? "bg-gradient-to-r from-[#3a3e58] to-[#777f94]"
                        : ""
                    }`}
                    // onClick={startScreening}
                    disabled={startScreeningDisabled}
                    onClick={() => {
                      //commented on 2024-11-12 bcs special educator and professoinal usertype process same as teacher
                      // if ((userDetails.userTypeId === 7 || userDetails.userTypeId === 8) && studentForm.schoolName) {
                      //   if (schoolEnter === true) {
                      //     addSchool(stateId, districtId, blockId, userId);
                      //   } else {
                      //     startScreeningOne();
                      //   }
                      // } else {
                      startScreeningOne();
                      //}
                    }}
                  >
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "label_btn_screen"
                        )[0].keyValue
                      : "Start Screening 1"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <ScreeningQA
          studentForm={studentForm}
          studentResponse={studentResponse}
          setStudForm={setStudForm}
        />
      )}

      <>
        {/* Already student exists model */}
        <Modal visible={showAlreadyExistsModal}>
          <div className="w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
            <img
              src="../../Images/confirm-icon.svg"
              alt="Puzzle Smiley Icon"
              className="w-[25%] m-auto"
            />
            <h2 className="text-[17px] my-2 text-[#101942]">
              <b> Already exists </b>
            </h2>

            <h6 className="text-[14px] text-[#565D7A]">
              Record already exists..!!!
            </h6>
            <br />

            <p
              className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center cursor-pointer"
              onClick={() => setShowAlreadyExistsModal(false)}
            >
              Got it!
            </p>
          </div>
        </Modal>

        {/* Already request submit success model */}
        <Modal visible={showAlreadyRequestModal}>
          <div className="w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
            <img
              src="../../Images/Successmark.svg"
              alt="Puzzle Smiley Icon"
              className="w-[25%] m-auto"
            />
            <h2 className="text-[17px] my-2 text-[#101942]">
              <b> Already requested </b>
            </h2>

            <h6 className="text-[14px] text-[#565D7A]">
              Your student transfer request is already registered. Please wait
              for the response
            </h6>
            <br />
            <p
              className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center cursor-pointer"
              onClick={() => {
                setShowAlreadyRequestModal(false);
                //setShowAlreadyAssessedModal(true);
              }}
            >
              Got it!
            </p>
          </div>
        </Modal>

        {/* Already assessed submit success model */}
        <Modal visible={showAlreadyAssessedModal}>
          <div className="relative w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
            <p
              className="absolute top-4 right-4 bg-[#f6f7fa] rounded-full w-[30px] h-[30px] flex justify-center items-center cursor-pointer z-10"
              onClick={() => setShowAlreadyAssessedModal(false)}
            >
              <FontAwesomeIcon
                icon={faXmark}
                size="lg"
                style={{ color: "#3c3f4f" }}
              />
            </p>
            <img
              src="../../Images/assessed.svg"
              alt="Puzzle Smiley Icon"
              className="w-[25%] m-auto"
            />
            <h2 className="text-[17px] my-2 text-[#101942]">
              <b> Already assessed </b>
            </h2>
            <h3 className="text-[17px] my-2 text-[#101942]">
              {" "}
              This student has already been assessed by{" "}
            </h3>
            <br></br>
            <div
              style={{
                padding: "15px",
                display: "inline-block",
                border: "2px dashed #D3D3D3",
                borderRadius: "10px",
              }}
            >
              <h2 className="text-[17px] text-[#101942]">
                <b style={{ color: "#4169E1" }}>
                  {" "}
                  {assessedTeacherName === "" || assessedTeacherName === null
                    ? "Another Teacher"
                    : assessedTeacherName}{" "}
                </b>
              </h2>
              <h6 className="text-[14px] text-[#565D7A]">
                {assessedTeacherSchoolName === "" ||
                assessedTeacherSchoolName === null
                  ? "This student has been in Pool"
                  : assessedTeacherSchoolName}
              </h6>
            </div>
            <h1>
              <br />
              <br />
              <br />
            </h1>
            <h6 className="text-[14px] text-[#565D7A]">
              Would you like to request the transfer of the student to your case
              records?
            </h6>
            <p
              className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center cursor-pointer"
              onClick={() => {
                sendCaseTransferRequestOnClick();
              }}
            >
              Send transfer request
            </p>
          </div>
        </Modal>

        {/* Request sent successfully model */}
        <Modal visible={showRequestSentModal}>
          <div className="w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
            <img
              src="../../Images/message.svg"
              alt="Puzzle Smiley Icon"
              className="w-[25%] m-auto"
            />
            <h2 className="text-[17px] my-2 text-[#101942]">
              <b> Request sent successfully </b>
            </h2>

            <h6 className="text-[14px] text-[#565D7A]">
              Your request to transfer the student has been submitted
              successfully. You will be notified once the request is reviewed
              and accepted.
            </h6>
            <br />

            <p
              className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center cursor-pointer"
              onClick={() => {
                setShowRequestSentModal(false);
                //navigate("/user/screening-dashboard");
              }}
            >
              Got it!
            </p>
          </div>
        </Modal>

        {/* Transfer successfully model */}
        <Modal visible={showTransferSuccessModal}>
          <div className="w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
            <img
              src="../../Images/Successmark.svg"
              alt="Puzzle Smiley Icon"
              className="w-[25%] m-auto"
            />
            <h2 className="text-[17px] my-2 text-[#101942]">
              <b> Transfer Successful </b>
            </h2>

            <h6 className="text-[14px] text-[#565D7A]">
              Your request to transfer <b>{assessedStudentName}</b> is
              confirmed. Student has been assigned to you.
            </h6>
            <br />

            <p
              className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center cursor-pointer"
              onClick={() => {
                setShowTransferSuccessModal(false);
              }}
            >
              Got it!
            </p>
          </div>
        </Modal>
      </>
    </>
  );
};

export default AddStudentForm;
