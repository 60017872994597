import React, { useState, useEffect } from "react";
import puzzleSmile from "../../../../../src/assets/images/puzzle_smile.svg";
import bulletsIcon from "../../../../../src/assets/images/bullets.svg";
import { postAdvanceScreeningResult } from "../Api/ScreeningApi";
import { useLocation, useNavigate } from "react-router-dom";
import { getTranslatedTextSelector } from "../../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function AdvanceScreeningReport() {
  const location = useLocation();
  const navigate = useNavigate();
  const [advanceReport, setAdvanceReport] = useState();
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const languageId = JSON.parse(localStorage.getItem("l_id"));

  console.log(location.state);
  const getAdancveScreeningReport = () => {
    const { studentID, userID } = location.state;
    postAdvanceScreeningResult(studentID, userID, languageId).then(
      (response) => {
        console.log(response[0], "akki");
        setAdvanceReport(response[0]);
      }
    );
  };

  const handleRedirect = () => {
    window.location.href = "/user/intervention";
  };

  useEffect(() => {
    getAdancveScreeningReport();
  }, []);

  return (
    <>
      <div className="bg-[#f4f4fa] py-5 px-8 h-[calc(100vh_-_9vh)]">
        <div className="rounded-[30px] bg-[#fff] h-full overflow-y-scroll no-scrollbar">
          <div className="py-4 px-8 border-b-[2px] border-[#f4f4fa] text-left flex items-center">
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ color: "#000000" }}
              size="xl"
              onClick={() => navigate(-1)}
              className="mr-5 cursor-pointer"
            />
            <h2 className="text-[#101942] font-semibold text-[22px]">
              {getTranslateText?.data.length > 0
                ? getTranslateText?.data?.filter(
                    (item) => item.pageKey === "key_view_report"
                  )[0]?.keyValue
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "key_view_report"
                    )[0]?.keyValue
                  : "View Report"
                : "View Report"}
            </h2>
          </div>
          <div className="py-5">
            <div className="px-8 pb-5 [&_p]:text-[#101942cc] text-left [&_p]:flex [&_p]:mt-2 [&_p]:text-[14px]">
              <h4 className="font-medium text-[18px]">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "key_concern"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "key_concern"
                      )[0]?.keyValue
                    : "The child is showing concerns:"
                  : "The child is showing concerns:"}
              </h4>
              <p>
                <img
                  src={bulletsIcon}
                  alt="Bullet Icon"
                  className="mr-2 w-[10px]"
                />{" "}
                {advanceReport?.statement}
              </p>

              {/* <p>
                {" "}
                <img
                  src={bulletsIcon}
                  alt="Bullet Icon"
                  className="mr-2 w-[10px]"
                />{" "}
                Indicating at a risk of autism, ADHD.
              </p> */}
            </div>
            <div className="bg-[#FFF9F0] rounded-[10px] p-5 text-left mx-8 p-3 hidden">
              <h3 className="text-[#101942] text-[18px] font-medium ">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "key_advice"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "key_advice"
                      )[0]?.keyValue
                    : "Advice"
                  : "Advice"}
              </h3>
              <p className="text-[#101942cc] font-normal text-[14px] ">
                Refer to school counselor, special educator and module-3 of
                course (Learning difficulty- understanding and management ) for
                classroom management
              </p>
            </div>
          </div>
          <div className="mx-8 my-12">
            <div className="bg-[#F6F8FC] rounded-[10px] flex flex-col items-center ">
              <img
                src={puzzleSmile}
                alt="Puzzle Smile Image"
                className=" md:w-[15%] lg:w-[10%] w-[30%] md:mt-[-35px] lg:mt-[-45px] mt-[-35px]"
              />
              <p className="text-[#101942cc] md:text-[16px] text-[14px] my-5 mx-6">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "key_profile_updated_class"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "key_profile_updated_class"
                      )[0]?.keyValue
                    : "This student's profiler has been updated in classroom observation section"
                  : "This student's profiler has been updated in classroom observation section"}
              </p>
              <button
                className="text-[#3C57AD] text-[14px] font-medium my-5 hidden"
                onClick={() => {
                  // setProfileVisible(true);
                  // setGetStudentId(data.studentID);
                  navigate("/user/student-profile", {
                    state: { student_id: location?.state?.studentID },
                  });
                  console.log(location.state.data);
                }}
              >
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "key_view_profiler"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "key_view_profiler"
                      )[0]?.keyValue
                    : "View profiler"
                  : "View profiler"}{" "}
                {">"}{" "}
              </button>
            </div>
            <button
              type="submit"
              className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] py-3 md:py-2 px-5 rounded-[10px] m-auto flex justify-center relative mt-6 lg:w-[30%] w-[80%] cursor-not-allowed hidden"
              onClick={handleRedirect}
              disabled={true}
            >
              {getTranslateText?.data.length > 0
                ? getTranslateText?.data?.filter(
                    (item) => item.pageKey === "key_start_intervention"
                  )[0]?.keyValue
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "key_start_intervention"
                    )[0]?.keyValue
                  : "Start Intervention"
                : "Start Intervention"}
              {/* <ReactIcons.AiFillRightCircle className="absolute right-3 top-[13px] text-[#586084] text-xl" /> */}
              <span className="bg-[#586084] p-[8px] rounded-[100%] absolute right-3 top-[12px] md:top-[8px]">
                <img src="../right-arrow.png" className="w-[8px] h-[10px]" />
              </span>
            </button>

            <p
              className="text-[#848BAA] text-[14px] mt-6 cursor-pointer hidden"
              onClick={() => {
                navigate("/user/screening-dashboard");
              }}
            >
              {getTranslateText?.data.length > 0
                ? getTranslateText?.data?.filter(
                    (item) => item.pageKey === "label_later"
                  )[0]?.keyValue
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "label_later"
                    )[0]?.keyValue
                  : "I will do later"
                : "I will do later"}{" "}
              {">"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvanceScreeningReport;
