import React, { useEffect, useRef, useState } from "react";

import { faL, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTranslatedTextSelector } from "../../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import "react-step-progress-bar/styles.css";
import Modal from "../../../modal/Modal";
import { useLocation, useNavigate } from "react-router-dom";
// import ScreeningDashboard from './ScreeningDashboard';
import { useMediaQuery } from "react-responsive";
import {
  GetDomainProfilerForm,
  PostScreeningProfilerForm,
  getProfilerFormQuestionAnswers,
  updateStudentScreeningStatus,
} from "../Api/ScreeningApi";
import puzzleSmile from "../../../../assets/images/puzzle_smile.svg";
import "./ProfilerForm.css";
import Loader from "../../../../utils/loader/Loader";
import { fetchInstruction } from "../../../../utils/instruction/InstructionApi";

const ProfilerForm = (props) => {
  const [showDashboard, setShowDashboard] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const lastQuestionRef = useRef(null);
  let languageId = localStorage.getItem("l_id");
  const _gradeId = location?.state?.gradeID;
  // const navigation = useNavigate();
  const { srcData } = props;
  // console.log(location?.state?.studentID, "sharwan");
  const [loader, setLoarder] = useState();
  const [instruction, setInstruction] = useState();

  const [report, setReport] = useState(true);
  const [questionAnswer, setQuestionAnswer] = useState([]);
  const [totalQuestion, setTotalQuestion] = useState([]);
  const [subQuestion, setSubqestion] = useState([]);
  const [subQuestionIndex, setSubqestionIndex] = useState();
  const [domainMaster, setDomainMaster] = useState([]);
  const [isShowMultiSaveButton, setIsShowMultiSaveButton] = useState(false);
  const [showingMultipleButton, setShowMultipleButton] = useState(0);
  const [index, setIndex] = useState(1);
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const [answerSheet, setAnswerSheet] = useState({
    studentId: location?.state?.studentID,
    screeningQuestionOption: [],
    languageId: languageId,
    screeningCategoryId: 2,
    userTypeID: JSON.parse(localStorage.getItem("user")).userTypeId,
    userID: location?.state?.userID ?? localStorage.getItem("id"),
    domainMasterId: 0,
  });
  //   console.log(domainMasterId[{}], "akki");

  const handleSubmit = () => {
    PostScreeningProfilerForm(answerSheet)
      .then((res) => {
        // getQuestionAnswer();
        setIndex(1);
        setQuestionAnswer([]);
        setTotalQuestion([]);
        setSubqestion([]);
        setSubqestionIndex();
        getdomainProfilerForm();
        setShowRecheckModal(false);
        // setAnswerSheet({ ...answerSheet, screeningQuestionOption: [] });
      })
      .catch((error) => {});
  };

  const scrollToBottom = () => {
    lastQuestionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const currentQues = answerSheet.screeningQuestionOption.length;
  const totalQues = questionAnswer?.length;
  const progress = (currentQues / totalQues) * 100;

  const [showModal, setShowModal] = useState(false);
  // Modal 2 //
  const [showModal2, setShowModal2] = useState(false);
  //   Submit Modal //
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showRecheckModal, setShowRecheckModal] = useState(false);
  const [mobileOn, setMobileOn] = useState(false);

  const getdomainProfilerForm = () => {
    const { studentID } = location?.state;
    const userID =
      location?.state?.userID ?? localStorage.getItem("id") ?? null;
    console.log(userID, "UserID");

    GetDomainProfilerForm(studentID, 2, userID)
      .then((res) => {
        setDomainMaster(res);
        // const arr = res.map((item) => item.isScreeningCompleted === 0);
        const firstElementWithZero = res.find(
          (element) => element.isScreeningCompleted === 0
        );

        const screeningStatus = res.every(
          (item) => item.isScreeningCompleted === 1
        );

        if (screeningStatus) {
          setShowSubmitModal(true);
        }

        setAnswerSheet({
          ...answerSheet,
          domainMasterId: firstElementWithZero.domainID,
        });
      })
      .catch((error) => {});
  };

  const getQuestionAnswer = () => {
    setLoarder(true);
    const languageId = localStorage.getItem("l_id");
    getProfilerFormQuestionAnswers(languageId, 2, answerSheet.domainMasterId)
      .then((res) => {
        setTotalQuestion(res);
        const questionAnswer = res.filter((item) => item?.isSubQuestion === 0);
        setQuestionAnswer(questionAnswer);
        setAnswerSheet({ ...answerSheet, screeningQuestionOption: [] });
        setLoarder(false);
      })
      .catch((error) => {
        console.log(error, "errrrr");
      });
  };

  const submitMultipleOption = () => {
    setIndex(index + 1);
    setShowMultipleButton(0);
  };

  const handleAnswer = (item, subItem) => {
    const existingQuestion = answerSheet.screeningQuestionOption.find(
      ({ questionid }) => questionid === item.questionid
    );

    if (existingQuestion) {
      const updatedOptions = answerSheet.screeningQuestionOption.map(
        (option) => {
          if (option.questionid === existingQuestion.questionid) {
            return {
              ...option,
              optionid: [{ optionid: subItem.optionid }],
            };
          }
          return option;
        }
      );

      if (subItem.isSubQuestion === 1) {
        const findOptionSubQuestion = totalQuestion?.filter(
          (item) => item.questionid === subItem.subQuestionID
        );
        const findOptionSubQuestionIndex = totalQuestion?.findIndex(
          (item) => item.questionid === subItem.subQuestionID
        );

        setSubqestion(findOptionSubQuestion);
        setSubqestionIndex(findOptionSubQuestionIndex);
      } else {
        setSubqestion([]);
        setSubqestionIndex();
      }
      if (item.questionTypeID === 1) {
        setShowMultipleButton(item.questionid);
        const data = { ...answerSheet };

        const findQuestionIndex = data?.screeningQuestionOption?.findIndex(
          (question) => question.questionid === item.questionid
        );
        if (
          data?.screeningQuestionOption?.[findQuestionIndex]?.optionid.length >
          0
        ) {
          const newData = data?.screeningQuestionOption?.[
            findQuestionIndex
          ]?.optionid.findIndex((item) => item.optionid === subItem.optionid);

          if (newData !== -1) {
            data?.screeningQuestionOption?.[findQuestionIndex]?.optionid.splice(
              newData,
              1
            );
            setAnswerSheet(data);
            return;
          }
        }
        const newArray = data?.screeningQuestionOption?.[
          findQuestionIndex
        ]?.optionid.concat({ optionid: subItem.optionid });

        data?.screeningQuestionOption?.[findQuestionIndex]?.optionid.splice(
          0,
          data?.screeningQuestionOption?.[findQuestionIndex]?.optionid.length,
          ...newArray
        );

        setAnswerSheet(data);
      } else {
        setAnswerSheet({
          ...answerSheet,
          screeningQuestionOption: updatedOptions,
        });
      }
    } else {
      const finalData = {
        questionid: item.questionid,
        optionid: [{ optionid: subItem.optionid }],
        isSubquestion: item.isSubQuestion,
        subQuestionId: item.hasSubQuestionID,
      };

      setAnswerSheet({
        ...answerSheet,
        screeningQuestionOption: [
          ...answerSheet.screeningQuestionOption,
          finalData,
        ],
      });
      if (subItem.isSubQuestion === 1) {
        const findOptionSubQuestion = totalQuestion?.filter(
          (item) => item.questionid === subItem.subQuestionID
        );
        const findOptionSubQuestionIndex = totalQuestion?.findIndex(
          (item) => item.questionid === subItem.subQuestionID
        );
        setSubqestion(findOptionSubQuestion);
        setSubqestionIndex(findOptionSubQuestionIndex);
      } else {
        setSubqestion([]);
        setSubqestionIndex();
      }
      if (item.questionTypeID === 1) {
        setShowMultipleButton(item.questionid);
      } else {
        setIndex(index + 1);
      }
    }
  };

  const isSmallScreen = useMediaQuery({ query: "(max-width: 440px)" });

  useEffect(() => {
    isSmallScreen ? setMobileOn(true) : setMobileOn(false);
  }, [isSmallScreen]);

  useEffect(() => {
    getdomainProfilerForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageId]);

  useEffect(() => {
    if (answerSheet.domainMasterId) {
      getQuestionAnswer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerSheet.domainMasterId, languageId]);

  useEffect(() => {
    scrollToBottom();
  }, [index]);

  useEffect(() => {
    if (subQuestionIndex) {
      const list =
        subQuestion?.length > 0
          ? [
              ...questionAnswer?.slice(0, subQuestionIndex),
              subQuestion[0],
              ...questionAnswer.slice(subQuestionIndex),
            ]
          : questionAnswer;

      setQuestionAnswer(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subQuestionIndex, languageId]);

  const updateStudentStatus = async (location) => {
    updateStudentScreeningStatus(location.studentID, 3)
      .then((res) => {
        navigate("/user/advance-screening", {
          state: location,
          grade: _gradeId,
        });
        localStorage.setItem("tabsId", 3);
      })
      .catch((error) => {
        console.log(error, "errroor");
      });
  };

  console.log(_gradeId, "gradegradeId");

  // Instruction api calling //
  const getProfilerInstruction = (l_id) => {
    fetchInstruction("Profiler Form Disclaimer", languageId).then(
      (response) => {
        if (response.response[0] === undefined) {
          languageId = 1;
          getProfilerInstruction(languageId);
        } else {
          setInstruction(response.response[0]);
          // setLoader(false);
        }
      }
    );
  };
  useEffect(() => {
    getProfilerInstruction();
  }, [languageId]);

  console.log(location?.state, "profiler state");

  return (
    <>
      {!showDashboard ? (
        <>
          {report ? (
            <div className="bg-[#F4F4FA] md:py-[30px] lg:px-[50px] px-[0px] md:h-[calc(100%_-_9%)] h-[calc(100%_-_10%)] overflow-y-scroll no-scrollbar">
              <div className="bg-[#fff] rounded-[25px] h-[100%] ">
                <div className="clsnames rounded-[20px] bg-gradient-to-b from-[#DFE5FF] from-[0%] via-[#fff] via-[30%] to-[#fff] to-[70%] flex flex-row justify-between items-center lg:px-[50px] px-[20px] pt-[10px]">
                  <h2 className="text-[#3C3F4F] font-semibold text-left lg:text-[22px] text-[18px] py-[20px]  mt-[] ">
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "label_screening_2"
                        )[0].keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "label_screening_2"
                          )[0].keyValue
                        : "Profiler Form"
                      : "Profiler Form"}
                  </h2>
                  <div className="hidden flex-row items-center md:flex">
                    {domainMaster.map((item, index) => {
                      return (
                        <div
                          className={`flex [&_p]:py-2 [&_p]:px-4 [&_p]:rounded-full [&_p]:text-[#fff] [&_p]:mxs-2 after:border-r-2 after:rotate-90 after:border-dotted after:h-[20px] after:w-[25px] last:after:border-0 after:border-[#C5CBE4] after:py-[20px] after:my-[-10px] after:mx-[7px] ${
                            item?.isScreeningCompleted === 1 ||
                            answerSheet.domainMasterId === item.domainID
                              ? "[&_p]:bg-[#263992]"
                              : "[&_p]:bg-[#C5CBE4]"
                          }`}
                        >
                          <p className="">{index + 1}</p>
                        </div>
                      );
                    })}
                  </div>

                  <div className="flex flex-row">
                    <img
                      src="../Images/book.svg"
                      alt=""
                      className="md:mr-[40px] mr-[10px] w-[20px] cursor-pointer"
                      onClick={() => setShowModal(true)}
                    />
                    <p
                      className="bg-[#f6f7fa] rounded-full py-[8px] w-[40px] h-[40px] cursor-pointer"
                      onClick={() => setShowModal2(true)}
                      // onClick={() => setShowSubmitModal(true)}
                    >
                      <FontAwesomeIcon
                        icon={faXmark}
                        size="lg"
                        style={{ color: "#3c3f4f" }}
                      />
                    </p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center my-4 md:hidden">
                  {domainMaster.map((item, index) => {
                    return (
                      <div
                        className={`flex [&_p]:py-2 [&_p]:px-4 [&_p]:rounded-full [&_p]:text-[#fff] [&_p]:mxs-2 after:border-r-2 after:rotate-90 after:border-dotted after:h-[20px] after:w-[25px] last:after:border-0 after:border-[#C5CBE4] after:py-[20px] after:my-[-10px] after:mx-[7px] ${
                          item?.isScreeningCompleted === 1 ||
                          answerSheet.domainMasterId === item.domainID
                            ? "[&_p]:bg-[#263992]"
                            : "[&_p]:bg-[#C5CBE4]"
                        }`}
                      >
                        <p className="">{index + 1}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="border-t-2 h-[calc(100%_-_15%)] py-[50px] bg-puzzle">
                  <div className="w-[85%] lg:w-[100%] ml-[50px] pr-[60px] overflow-y-scroll float-rights flex flex-col relative h-[100%]">
                    {loader !== true ? (
                      <>
                        {questionAnswer &&
                          questionAnswer?.slice(0, index)?.map((item, i) => (
                            <div className="mb-[50px] flex flex-col justify-end">
                              <div className=" rounded-tl-[4px] rounded-r-[20px] rounded-bl-[20px] p-5">
                                <p className="text-left text-[#101942] font-medium">
                                  {item?.questionname}
                                </p>
                              </div>
                              <div className="flex flex-row justify-end flex-wrap">
                                {questionAnswer &&
                                  questionAnswer[i].questionoptions?.map(
                                    (subItem, subIndex) => {
                                      return (
                                        <div
                                          className=" float-right mt-[30px]"
                                          key={subIndex}
                                        >
                                          <button
                                            className={`${
                                              answerSheet.screeningQuestionOption[
                                                i
                                              ]?.optionid.some(
                                                (item) =>
                                                  item.optionid ===
                                                  subItem.optionid
                                              )
                                                ? "bg-[#F3F6FF] text-[#3650C9] border-[#F3F6FF]"
                                                : "bg-[#fff]"
                                            } border-[#DCDDE3] border-[2px] hover:bg-[#F3F6FF] text-[16px] text-[#101942] rounded-[10px] py-2 px-4 ml-[20px] font-light`}
                                            onClick={() => {
                                              handleAnswer(item, subItem);
                                              if (item.questionTypeID === 1) {
                                                setIsShowMultiSaveButton(true);
                                              }
                                            }}
                                          >
                                            {subItem.optionname}
                                          </button>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                              {showingMultipleButton === item.questionid && (
                                <div className="flex justify-end mt-[20px]">
                                  <button
                                    className="bg-gradient-to-r from-[#2235A1] to-[#121F55] rounded-[10px] py-[10px] px-[20px] text-white mb-[20px]"
                                    onClick={submitMultipleOption}
                                  >
                                    {getTranslateText?.data.length > 0
                                      ? getTranslateText?.data?.filter(
                                          (item) => item.pageKey === "key_save"
                                        )[0]?.keyValue
                                        ? getTranslateText?.data?.filter(
                                            (item) =>
                                              item.pageKey === "key_save"
                                          )[0]?.keyValue
                                        : "Save"
                                      : "Save"}
                                  </button>
                                </div>
                              )}
                            </div>
                          ))}

                        <div ref={lastQuestionRef}></div>
                        {!showingMultipleButton &&
                        questionAnswer?.length ===
                          answerSheet.screeningQuestionOption.length ? (
                          <button
                            className="bg-gradient-to-r from-[#2235A1] to-[#121F55] rounded-[10px] py-[10px] px-[80px] text-white mb-[20px]"
                            onClick={() => {
                              //handleSubmit();
                              setShowRecheckModal(true);
                            }}
                            // onClick={() => setShowSubmitModal(true)}

                            // item?.isScreeningCompleted === 1
                          >
                            {getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "key_save_continue"
                                )[0]?.keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "key_save_continue"
                                  )[0]?.keyValue
                                : "Save & Continue"
                              : "Save & Continue"}
                            {/* Save & Proceed */}
                          </button>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <div className="flex justify-center items-center h-[80%] w-full">
                        <Loader type="spinningBubbles" color="#2e376c" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // <Report
            //     studentReport={studentReport}
            //     setStudForm={setStudForm}
            //     // setShowQna={setShowQna}
            // />
            ""
          )}
        </>
      ) : (
        // <ScreeningDashboard />
        ""
      )}

      {/* Modal Component */}
      <Modal
        onClick={() => setShowModal(false)}
        onClose={() => setShowModal(false)}
        visible={showModal}
      >
        <div>
          {/* Modal Content */}
          <div className=" lg:w-5/12 md:w-8/12 w-10/12 m-auto ">
            <div className="flex justify-between bg-gradient-to-r from-[#2C3EA2] to-[#192867] px-6 py-4 rounded-t-[30px]">
              <h2 className="text-xl font-semibold  text-[#fff]  text-left">
                {instruction?.defaultName}
              </h2>
              <p
                className="bg-[#f6f7fa] rounded-full py-[4px] w-[30px] h-[30px] cursor-pointer"
                onClick={() => setShowModal(false)}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  size="lg"
                  style={{ color: "#2C3EA2" }}
                />
              </p>
            </div>
            <div className="bg-[#fff] px-6 py-6 rounded-b-[30px]">
              <h5 className="text-left">{instruction?.defaultscreenName}</h5>
              <ul className="list-disc px-6 text-left">
                <li>{instruction?.description}</li>
              </ul>
              <button
                onClick={() => setShowModal(false)}
                className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center hidden"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Exit Modal */}
      <Modal visible={showModal2}>
        <div>
          {/* Modal Content */}
          <div className=" w-12/12 m-auto ">
            <div className=""></div>
            <div className="bg-[#fff] px-6 py-6 rounded-[30px] flex flex-col justify-center items-center">
              <img src="../Images/sad_puzzle.svg" alt="Puzzle sad Image" />
              <h5 className="font-semibold text-[20px] my-2">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "key_want_to_leave"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "key_want_to_leave"
                      )[0]?.keyValue
                    : "Want to Leave?"
                  : "Want to Leave?"}
              </h5>
              <p className="w-[80%]">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) =>
                        item.pageKey ===
                        "key_dont_worry_you_can_continue_from_where_you_left"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) =>
                          item.pageKey ===
                          "key_dont_worry_you_can_continue_from_where_you_left"
                      )[0]?.keyValue
                    : "Don't worry you can continue from where you left."
                  : "Don't worry you can continue from where you left."}
              </p>
              <div className="flex justify-around w-[100%]">
                <button
                  // onClick={() => setShowQna(false)}
                  onClick={() => {
                    setShowDashboard(true);
                    navigate("/user/screening-dashboard");
                    setShowModal2(false);
                  }}
                  className="bg-gradient-to-r from-[#E7EAFF] to-[#E7EAFF] text-[#3C57AD] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                >
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "key_leave"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "key_leave"
                        )[0]?.keyValue
                      : "Leave"
                    : "Leave"}
                </button>
                <button
                  onClick={() => setShowModal2(false)}
                  className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                >
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "label_cancel"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "label_cancel"
                        )[0]?.keyValue
                      : "Cancel"
                    : "Cancel"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* ===== Submit Modal ===== */}
      {showSubmitModal && (
        <Modal
          onClick={() => setShowSubmitModal(false)}
          // onClose={() => setShowSubmitModal(false)}
          visible={showSubmitModal}
        >
          <div>
            {/* Modal Content */}
            <div className=" w-full md:w-[450px] m-auto rounded-[30px] ">
              <div className="">
                {/* <h2 className="text-xl font-semibold bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-6 py-4 rounded-t-[30px] text-left">
              Screening 1
            </h2> */}
              </div>
              <div className="bg-[#eff2fa] rounded-[30px] ">
                <div className="px-6 pt-6 flex flex-col justify-center items-center">
                  <img
                    src={puzzleSmile}
                    alt=""
                    className="md:w-[30%] w-[50%]"
                  />
                  <h5 className="font-semibold md:text-[22px] text-[20px] text-[#101942]">
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "thanks_for_submitting"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "thanks_for_submitting"
                          )[0]?.keyValue
                        : "Thanks for submitting!"
                      : "Thanks for submitting!"}
                  </h5>
                  <p className="w-[80%] text-[#3C57AD] font-light text-[14px]">
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) =>
                            item.pageKey ===
                            "key_this_students_profiler_has_been_updated"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) =>
                              item.pageKey ===
                              "key_this_students_profiler_has_been_updated"
                          )[0]?.keyValue
                        : "This student's profiler has been updated"
                      : "This student's profiler has been updated"}
                  </p>
                  <button
                    className="py-8 text-[#3C57AD] md:text-[16px] text-[14px] cursor-pointer font-medium"
                    onClick={() => {
                      // setProfileVisible(true);
                      // setGetStudentId(data.studentID);
                      // navigate("/user/student-profile", {
                      //   state: { data },
                      // });
                      setShowSubmitModal(false);
                      const profilereportdata = {
                        ...location.state,
                        // activeScreeningCategoryID:
                        //   location.state.activeScreeningCategoryID + 1,
                        currentScreeningStatus:
                          (location.state.currentScreeningStatus = "Done"),
                      };

                      navigate("/user/student-profile", {
                        state: { data: profilereportdata },
                      });
                    }}
                  >
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "key_view_profiler"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "key_view_profiler"
                          )[0]?.keyValue
                        : "View profiler"
                      : "View profiler"}
                    {" >"}
                  </button>
                </div>
                <div className="flex flex-col justify-center items-center w-[100%] bg-[#FFFFFF] rounded-b-[30px] p-5">
                  <button
                    // onClick={() => setShowQna(false)}
                    // onClick={() => {
                    //   setShowDashboard(true);
                    //   navigate("/user/screening");
                    //   setShowModal2(false);
                    // }}

                    className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[20px] py-[10px] rounded-[10px] mt-[20px] text-center md:w-[70%] w-[85%] block mb-5 cursor-pointer md:text-[16px] text-[14px] cursor-disabled"
                    onClick={() => {
                      updateStudentStatus(location.state);
                    }}
                  >
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) =>
                            item.pageKey === "key_start_advance_screening"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) =>
                              item.pageKey === "key_start_advance_screening"
                          )[0]?.keyValue
                        : "Start Advance Screening"
                      : "Start Advance Screening"}
                  </button>
                  <p
                    className="text-[#848BAA] text-center mb-5 cursor-pointer md:text-[16px] text-[14px]"
                    onClick={() => {
                      setShowDashboard(true);
                      navigate("/user/screening-dashboard");
                      setShowSubmitModal(false);
                    }}
                  >
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "label_later"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "label_later"
                          )[0]?.keyValue
                        : "I will do later"
                      : "I will do later"}
                  </p>
                  {/* <button
                onClick={() => setShowModal2(false)}
                className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
              >
                Cancel
              </button> */}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* ===== Check again model pop-up before submitting profiler form input ===== */}
      <Modal
        onClick={() => setShowRecheckModal(false)}
        onClose={() => setShowRecheckModal(false)}
        visible={showRecheckModal}
      >
        <div>
          {/* Modal Content */}
          <div className=" w-full md:w-[450px] m-auto rounded-[30px] ">
            <div className="">
              {/* <h2 className="text-xl font-semibold bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-6 py-4 rounded-t-[30px] text-left">
              Screening 1
            </h2> */}
            </div>
            <div className="bg-[#fff] rounded-[30px] ">
              <div className="px-6 py-[40px] flex flex-col justify-center items-center">
                {/* <img
                    src={puzzleSmile}
                    alt=""
                    className="md:w-[30%] w-[50%]"
                  /> */}
                <h5 className="font-semibold md:text-[22px] text-[20px] text-[#101942]">
                  Want to submit?
                </h5>
                <p className="w-[80%] text-[#3C57AD] font-light text-[14px]">
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) =>
                          item.pageKey ===
                          "key_dear_teacher_would_you_like_to_submit_your_responses"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) =>
                            item.pageKey ===
                            "key_dear_teacher_would_you_like_to_submit_your_responses"
                        )[0]?.keyValue
                      : "Dear teacher, Would you like to submit your responses?"
                    : "Dear teacher, Would you like to submit your responses?"}
                </p>
                <div className="flex justify-around w-[100%]">
                  <button
                    // onClick={() => setShowQna(false)}
                    onClick={() => {
                      setShowRecheckModal(false);
                    }}
                    className="bg-gradient-to-r from-[#E7EAFF] to-[#E7EAFF] text-[#3C57AD] px-[35px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                  >
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "label_check_again"
                        )[0].keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "label_check_again"
                          )[0].keyValue
                        : "Check Again"
                      : "Check Again"}
                  </button>
                  <button
                    className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[35px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "submit_btn"
                        )[0].keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "submit_btn"
                          )[0].keyValue
                        : "Submit"
                      : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfilerForm;
